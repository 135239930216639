<template>
  <div class="layout-default">
    <HoTheHeader />
    <main class="main">
      <slot />
    </main>
    <HoTheFooter />
  </div>
</template>

<script setup lang="ts">
import { ContentInjectionKey, content } from '@/utils/content'

const route = useRoute()

const i18n = useI18n()
const config = useRuntimeConfig()

const userCloudStorage = useUserCloudStorage()
provide(UserCloudStorageInjectionKey, userCloudStorage)

const favoriteCircle = useFavoriteCircle(userCloudStorage)
provide(FavoriteCircleInjectionKey, favoriteCircle)

provide(ContentInjectionKey, content)

useHead(() => ({
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: `${i18n.t('meta.description')}`,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: `${i18n.t('meta.description')}`,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: `${i18n.t('meta.title.top')}`,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: `${config.public.url}${route.fullPath}`,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: `${config.public.url}/2024Winter/images/ogp${
        i18n.locale.value === 'en' ? '_en' : ''
      }.jpg`,
    },
  ],
}))

// お気に入りのため最新の状況を取得
await userCloudStorage.fetchUserStorage()
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.layout-default {
  min-height: 100vh;
}

.main {
  background: repeat top center url('@/assets/images/bg-paper.jpg');
}
</style>
